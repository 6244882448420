import { cn } from '@bem-react/classname'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import useBackendError from '@/hooks/useBackendError'

import { AppContent, AppProfile, AppSidebar } from '@/components/AppProfile'
import { Back } from '@/components/Back'
import { Exchange } from '@/components/Exchange'
import { Loading } from '@/components/Loading'
import { StepInfo } from '@/components/StepInfo'
import { Title } from '@/components/Title'

import { PaymentAddress } from './components/PaymentAddress'
import { SepaTransfer } from './components/SepaTransfer'
import { TransactionDetail } from './components/TransactionDetail'
import { transactionSelector } from './store/TransactionsSelector'
import {
  getTransactionThunk,
  resetTransaction,
} from './store/TransactionsSlice'
import './styles/Transaction.scss'

const cnTransaction = cn('Transaction')

export const Transaction: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const [setBackendError] = useBackendError()
  const { id } = useParams()
  const transaction = useAppSelector(transactionSelector)

  const [isLoading, setLoading] = useState<boolean>(true)

  const getData = async (id: number) => {
    try {
      await dispatch(getTransactionThunk(id)).unwrap()
      setLoading(false)
    } catch (errors) {
      setBackendError(errors)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      getData(+id)
    }

    return () => {
      dispatch(resetTransaction())
    }
  }, [id])

  const listInfo = [
    {
      title: t('New Order'),
      text: t(
        'You have created a new order to buy cryptocurrency. Please read the information provided carefully. By clicking the "Pay" button, you confirm the correctness of the data in the purchase order. ',
      ),
    },
    {
      title: t('Payment'),
      text: t(
        'Enter your card details to pay for the cryptocurrency purchase order. All data is protected by the payment provider. After a successful payment, the cryptocurrency will be sent to your internal address in the Exflow wallet.',
      ),
    },
    {
      title: t('Cards details'),
      text: t(
        'In the details of the transaction, you can monitor the status of your application. As soon as the application takes the status "Completed", the cryptocurrency will be sent to your address in the Exflow wallet. Return to the main page to see the balance of the wallet. ',
      ),
    },
  ]

  const listInfoSepa = [
    {
      title: t('Order'),
      text: t('Check that the data is correct'),
    },
    {
      title: t('SEPA TRANSFER'),
      text: t(
        'Check that the data is correct. The request has been created, we are waiting for your payment',
      ),
    },
  ]

  const listInfoAddress = [
    {
      title: t('SEPA details'),
      text: t(
        'Enter the details of the SEPA to which you want to receive funds',
      ),
    },
    {
      title: t('Send') + ' ' + transaction?.give,
      text:
        t('Send') +
        ' ' +
        transaction?.give +
        ' ' +
        t(
          'to the wallet below. We will complete the transfer as soon as we receive the cryptocurrency',
        ),
    },
  ]

  const cancelOrder = async () => {
    if (!id) return

    try {
      await getData(+id)
    } catch (error) {
      console.log('cancelOrder', error)
    }
  }

  const generateTitle = (): string => {
    if (!transaction) return ''

    switch (true) {
      case !!transaction.pay_wallet:
        return t('Address Asortio.com')
      case !!transaction.pay_url:
        return t('transaction-new-order') + `# ${transaction.id}`
      case !!transaction.manual:
        return t('SEPA TRANSFER') //44
      default:
        return t('transaction-title') + `# ${transaction.id}` //35
    }
  }

  const generateContent = (): ReactNode => {
    if (!transaction) return ''

    switch (true) {
      case !!transaction.pay_wallet:
        return <PaymentAddress transaction={transaction} />
      case !!transaction.pay_url:
        return <TransactionDetail transaction={transaction} />
      case !!transaction.manual:
        return <SepaTransfer transaction={transaction} cancel={cancelOrder} />
      default:
        return <TransactionDetail transaction={transaction} />
    }
  }

  const generateSidebar = (): ReactNode => {
    if (!transaction) return ''

    switch (true) {
      case !!transaction.pay_wallet:
        return (
          <StepInfo
            list={listInfoAddress}
            currentStepInfo={2}
            title={t('Transaction Steps')}
          />
        )
      case !!transaction.pay_url:
        return (
          <StepInfo
            list={listInfo}
            currentStepInfo={1}
            title={t('Transaction Steps')}
          />
        )
      case !!transaction.manual:
        return (
          <StepInfo
            list={listInfoSepa}
            currentStepInfo={2}
            title={t('Transaction Steps')}
          />
        )
      default:
        return <Exchange />
    }
  }

  return (
    <>
      <Helmet>
        <title>{generateTitle()}</title>
        <meta name='description' content={t('transactions-description')} />
      </Helmet>
      <AppProfile>
        <AppContent>
          <div className={cnTransaction('top')}>
            <Title>{generateTitle()}</Title>
            <Back text={t('Back')} />
          </div>

          {isLoading ? <Loading position='center' /> : <>{generateContent()}</>}
          <div className={cnTransaction('descTransaction')}>Cryptocurrencies are highly volatile and can experience sudden price fluctuations, which may result in a total loss of your investment. This website does not provide financial or investment advice; all decisions to buy or sell should be made independently or with the help of a qualified financial advisor. This notice provides you with information about the risks associated with investment products, in which you may invest, through services provided to you by Asortio Sp. z o. o. (Reg. no.: 0001042571, Licence No.: RDWW-824, City: Warsaw, Descriptor: Asor4crypto). By proceeding, you acknowledge that you have read, understood, and accept the risks outlined in this disclaimer, and you are fully responsible for any losses incurred.</div>
        </AppContent>

        <AppSidebar padding>
          {isLoading ? <Loading position='center' /> : <>{generateSidebar()}</>}
        </AppSidebar>
      </AppProfile>
    </>
  )
})
